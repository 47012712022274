export default [{
        path: '/',
        redirect: '/layout'
    },
    {
        path: '/layout',
        component: () => import("@/views/Layout"),
        redirect: '/layout/manufacturingEnterprise',
        name: 'layout',
        children: [
            // 首营品种--生产企业
            {
                path: 'manufacturingEnterprise',
                component: () => import("@/views/Layout/manufacturingEnterprise/learningContent")
            },
            {
                path: 'manufacturingEnterprise/materials',
                component: () => import("@/views/Layout/manufacturingEnterprise/materials")
            },
            {
                path: 'manufacturingEnterprise/learningContent',
                component: () => import("@/views/Layout/manufacturingEnterprise")
            },
            {
                path: 'manufacturingEnterprise/learningContent/pass',
                component: () => import("@/views/Layout/manufacturingEnterprise/pass")
            },
            // 首营品种--经营企业
            {
                path: 'conductAnEnterprise',
                component: () => import("@/views/Layout/conductAnEnterprise/learningContent")
            },
            {
                path: 'conductAnEnterprise/materials',
                component: () => import("@/views/Layout/conductAnEnterprise/materials")
            },
            {
                path: 'conductAnEnterprise/learningContent',
                component: () => import("@/views/Layout/conductAnEnterprise")
            },
            {
                path: 'conductAnEnterprise/pass',
                component: () => import("@/views/Layout/conductAnEnterprise/pass")
            },
            // 首营企业--生产企业
            {
                path: 'chieflyEnterprise',
                component: () => import("@/views/Layout/chieflyEnterprise/learningContent")
            },
            {
                path: 'chieflyEnterprise/materials',
                component: () => import("@/views/Layout/chieflyEnterprise/materials")
            },
            {
                path: 'chieflyEnterprise/learningContent',
                component: () => import("@/views/Layout/chieflyEnterprise")
            },
            {
                path: 'chieflyEnterprise/pass',
                component: () => import("@/views/Layout/chieflyEnterprise/pass")
            },
            // 首营企业--经营企业
            {
                path: 'manage',
                component: () => import("@/views/Layout/manage/learningContent")
            },
            {
                path: 'manage/materials',
                component: () => import("@/views/Layout/manage/materials")
            },
            {
                path: 'manage/learningContent',
                component: () => import("@/views/Layout/manage")
            },
            {
                path: 'manage/pass',
                component: () => import("@/views/Layout/manage/pass")
            },
            // 药品收货-一般药品收货
            {
                path: 'generalMedicine',
                component: () => import("@/views/Layout/generalMedicine/learningContent")
            },
            {
                path: 'generalMedicine/materials',
                component: () => import("@/views/Layout/generalMedicine/materials")
            },
            {
                path: 'generalMedicine/learningContent',
                component: () => import("@/views/Layout/generalMedicine")
            },
            // 药品收货-冷链药品
             {
                path: 'coldChainDrug',
                component: () => import("@/views/Layout/coldChainDrug/learningContent")
            },
            {
                path: 'coldChainDrug/materials',
                component: () => import("@/views/Layout/coldChainDrug/materials")
            },
            {
                path: 'coldChainDrug/learningContent',
                component: () => import("@/views/Layout/coldChainDrug")
            },
            {
                path: 'coldChainDrug/pass',
                component: () => import("@/views/Layout/coldChainDrug/pass")
            },
            // 药品收货-销后退回药品
            {
                path: 'backDrug',
                component: () => import("@/views/Layout/backDrug/learningContent")
            },
            // 药品验收-一般药品验收
             {
                path: 'generalMedicineGather',
                component: () => import("@/views/Layout/generalMedicineGather/learningContent")
            },
            {
                path: 'generalMedicineGather/notPass',
                component: () => import("@/views/Layout/generalMedicineGather/notPass")
            },
            {
                path: 'generalMedicineGather/pass',
                component: () => import("@/views/Layout/generalMedicineGather/pass")
            },
            // 药品验收-冷链药品验收
            {
                path: 'coldChainDrugGather',
                component: () => import("@/views/Layout/coldChainDrugGather/learningContent")
            },
            {
                path: 'coldChainDrugGather/notPass',
                component: () => import("@/views/Layout/coldChainDrugGather/notPass")
            },
            {
                path: 'coldChainDrugGather/pass',
                component: () => import("@/views/Layout/coldChainDrugGather/pass")
            },
            // 药品入库流程--整箱药品上架入库
            {
                path: 'FCLStorage',
                component: () => import("@/views/Layout/FCLStorage/learningContent")
            },
            // 药品入库流程--零售药品上架入库
            {
                path: 'retailStorage',
                component: () => import("@/views/Layout/retailStorage/learningContent")
            },
            // 药品销售出库复核--零货药品
            {
                path: 'retailReview',
                component: () => import("@/views/Layout/retailReview/learningContent")
            },
            // 药品销售出库复核--非立体库整箱药品
             {
                path: 'nonlinearityReview',
                component: () => import("@/views/Layout/nonlinearityReview/learningContent")
            },
            // 药品销售出库复核--自动立体库药品
            {
                path: 'stereoscopicReview',
                component: () => import("@/views/Layout/stereoscopicReview/learningContent")
            },
            // 药品发货流程--一般药品发货流程
            {
                path: 'ordinaryShipments',
                component: () => import("@/views/Layout/ordinaryShipments/learningContent")
            },
            // 药品发货流程--冷链药品发货流程
            {
                path: 'coldChainShipments',
                component: () => import("@/views/Layout/coldChainShipments/learningContent")
            },
            // 药品养护
            {
                path: 'drugConserve',
                component: () => import("@/views/Layout/drugConserve/learningContent")
            },
            {
                path: 'drugConserve/monthly',
                component: () => import("@/views/Layout/drugConserve/conserveMeiYue")
            },
            {
                path: 'drugConserve/daily',
                component: () => import("@/views/Layout/drugConserve/conserveRiChang")
            },

            // 翻页测试
            {
                path: 'demo',
                component: () => import("@/views/Layout/demo/learningContent")
            },
            {
                path: 'demo2',
                component: () => import("@/views/Layout/demo2/learningContent")
            },
































































            {
                path: 'receiving/steps1',
                component: () => import("@/views/Layout/receiving/steps1")
            },



        ]
    }
]