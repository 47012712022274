const { config } = require("chai");


// rem屏幕适配文件
window.onload = function (){
    // 清除冒泡
    document.addEventListener("touchstart", function(ev){
        ev = ev || event;
        ev.preventDefault();
    });

    // rem适配
    rem();
}

function rem(){
    var styleNode = document.createElement("style");
    var w  = document.documentElement.clientWidth / 16;
    styleNode.innerHTML = "html{font-size:"+w+"px !important}";
    document.head.appendChild(styleNode);
};

window.onresize = function(){
    rem()
}