<template>
  <div>
  <router-view></router-view>
  </div>
</template>
 
<script>


export default {
  data() {
    return {};
  },
  mounted() {
      
  },
  methods: {
     
  },
};
</script>
 
<style scoped lang = "less">

</style>
